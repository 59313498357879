* {
  margin: 0;
  padding: 0;
}

body {
  background-image: url(./images/background.png);
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
}

.middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}